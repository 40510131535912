import React from 'react';

const Minus = ({
  width = '13px',
  height = '2px',
  ...props
}: React.SVGProps<SVGSVGElement> ) => (
  <svg width={width} height={height} viewBox="0 0 13 2" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>4. Icons / 0. Icon Area@1x</title>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Pagina-categoria-/-NO-filtri-attivi-/-filtri-chiusi" transform="translate(-387.000000, -487.000000)" fill="#000000">
            <g id="FILTRI" transform="translate(165.000000, 464.000000)">
                <g id="4.-Icons-/-0.-Icon-Area" transform="translate(221.000000, 13.000000)">
                    <polygon id="Icon" transform="translate(7.500000, 11.000000) rotate(-90.000000) translate(-7.500000, -11.000000) " points="6.83333333 5 8.16666667 5 8.16666667 17 6.83333333 17"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default Minus;
