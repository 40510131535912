import * as React from "react";
import { SVGProps } from "react";

const SvgFooterFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width={38} height={38} xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M12 0h14c6.627 0 12 5.373 12 12v14c0 6.627-5.373 12-12 12H12C5.373 38 0 32.627 0 26V12C0 5.373 5.373 0 12 0Z"
        id="footer-facebook_svg__a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#ffffff"
        stroke="#E4E4E4"
        d="M26 .5c3.176 0 6.05 1.287 8.132 3.368A11.464 11.464 0 0 1 37.5 12v14c0 3.176-1.287 6.05-3.368 8.132A11.464 11.464 0 0 1 26 37.5H12c-3.176 0-6.05-1.287-8.132-3.368A11.464 11.464 0 0 1 .5 26V12c0-3.176 1.287-6.05 3.368-8.132A11.464 11.464 0 0 1 12 .5Z"
      />
      <path
        d="M21.032 26.5v-5.131H22.8l.265-2h-2.034v-1.277c0-.579.165-.974 1.017-.974h1.088v-1.789a14.934 14.934 0 0 0-1.585-.079c-1.568 0-2.641.932-2.641 2.644v1.474h-1.774v2h1.774V26.5H15.5a4 4 0 0 1-4-4v-7a4 4 0 0 1 4-4h7a4 4 0 0 1 4 4v7a4 4 0 0 1-4 4h-1.468Z"
        fill="#000"
      />
    </g>
  </svg>
);

export default SvgFooterFacebook;
