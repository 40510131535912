import React from 'react';

const SearchIcon = ({
  width = '25px',
  height = '25px',
  ...props
}: React.SVGProps<SVGSVGElement> ) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25 25"
    {...props}
  >
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="3.-Components/1.-Navigazione-/Menu" transform="translate(-1232.000000, -71.000000)" fill="currentColor">
        <g id="Menu-1°-livello" transform="translate(0.000000, 40.000000)">
          <g id="CERCA" transform="translate(972.000000, 14.000000)">
            <g id="🔶-Icon-SEARCH" transform="translate(260.941250, 17.941250)">
              <path
                d="M16.5219411,14.5393082 L15.4777544,14.5393082 L15.107663,14.1824342 C16.4029831,12.6756332 17.1828188,10.7194354 17.1828188,8.59140938 C17.1828188,3.84630789 13.3365109,0 8.59140938,0 C3.84630789,0 0,3.84630789 0,8.59140938 C0,13.3365109 3.84630789,17.1828188 8.59140938,17.1828188 C10.7194354,17.1828188 12.6756332,16.4029831 14.1824342,15.107663 L14.5393082,15.4777544 L14.5393082,16.5219411 L21.1480846,23.1175 L23.1175,21.1480846 L16.5219411,14.5393082 L16.5219411,14.5393082 Z M8.59140938,14.5393082 C5.30023871,14.5393082 2.64351058,11.88258 2.64351058,8.59140938 C2.64351058,5.30023871 5.30023871,2.64351058 8.59140938,2.64351058 C11.88258,2.64351058 14.5393082,5.30023871 14.5393082,8.59140938 C14.5393082,11.88258 11.88258,14.5393082 8.59140938,14.5393082 L8.59140938,14.5393082 Z"
                id="🔹-Icon-Color"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SearchIcon;
