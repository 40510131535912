import React from 'react';

const Plus = ({
  width = '13px',
  height = '12px',
  ...props
}: React.SVGProps<SVGSVGElement> ) => (
  <svg width={width} height={height} viewBox="0 0 13 12" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>4. Icons / 0. Icon Area@1x</title>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="SKU-V1-(overlay)-" transform="translate(-1209.000000, -403.000000)" fill="#000000">
            <g id="CONTENUTI" transform="translate(164.000000, 108.000000)">
                <g id="SIDEBAR" transform="translate(763.000000, 227.000000)">
                    <g id="4.-Altro/2.-List/Light/Small/Active" transform="translate(1.000000, 50.000000)">
                        <g id="4.-Icons-/-0.-Icon-Area" transform="translate(280.000000, 13.000000)">
                            <path d="M8.16666667,5 L8.166,10.333 L13.5,10.3333333 L13.5,11.6666667 L8.166,11.666 L8.16666667,17 L6.83333333,17 L6.833,11.666 L1.5,11.6666667 L1.5,10.3333333 L6.833,10.333 L6.83333333,5 L8.16666667,5 Z" id="Icon"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default Plus;
