import React from 'react';

const CaretDownIcon = ({
  width = '12px',
  height = '6px',
  ...props
}: React.SVGProps<SVGSVGElement> ) => (
  <svg width={width} height={height} viewBox="0 0 12 6" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="3.-Components/1.-Navigazione-/Menu" transform="translate(-233.000000, -17.000000)" fill="#969696">
        <g id="Language" transform="translate(165.000000, 11.000000)">
          <polygon id="🔹-Icon-freccia" transform="translate(74.000000, 9.375000) scale(1, -1) translate(-74.000000, -9.375000) " points="68.75 12 74 6.75 79.25 12"></polygon>
        </g>
      </g>
    </g>
  </svg>
)

export default CaretDownIcon;
