import * as React from "react";
import { SVGProps } from "react";

const SvgFooterLinkedin = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width={38} height={38} xmlns="http://www.w3.org/2000/svg">
    <defs>
        <path id="footer-linkedin-path-1" d="M12,0 L26,0 C32.627417,-1.21743675e-15 38,5.372583 38,12 L38,26 C38,32.627417 32.627417,38 26,38 L12,38 C5.372583,38 8.11624501e-16,32.627417 0,26 L0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 Z"></path>
    </defs>
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3.-Components/1.-Navigazione-/Footer" transform="translate(-309.000000, -145.000000)">
            <g id="Social" transform="translate(213.000000, 145.000000)">
                <g id="Medium" transform="translate(96.000000, 0.000000)">
                    <g id="4.-Altro/Border-Radius/1.-Full">
                        <mask id="footer-linkedin-mask-2" fill="white">
                            <use href="#footer-linkedin-path-1"></use>
                        </mask>
                        <path stroke="#E4E4E4" fill="#ffffff" d="M26,0.5 C29.1756373,0.5 32.0506373,1.78718134 34.131728,3.86827202 C36.2128187,5.94936269 37.5,8.82436269 37.5,12 L37.5,12 L37.5,26 C37.5,29.1756373 36.2128187,32.0506373 34.131728,34.131728 C32.0506373,36.2128187 29.1756373,37.5 26,37.5 L26,37.5 L12,37.5 C8.82436269,37.5 5.94936269,36.2128187 3.86827202,34.131728 C1.78718134,32.0506373 0.5,29.1756373 0.5,26 L0.5,26 L0.5,12 C0.5,8.82436269 1.78718134,5.94936269 3.86827202,3.86827202 C5.94936269,1.78718134 8.82436269,0.5 12,0.5 L12,0.5 Z"></path>
                    </g>
                    <g id="Icon" transform="translate(11.000000, 11.000000)" fill="#000000">
                        <path d="M13.6345353,13.6337778 L11.261959,13.6337778 L11.261959,9.92 C11.261959,9.03466667 11.2459581,7.896 10.0281127,7.896 C8.79426635,7.896 8.60581143,8.86133333 8.60581143,9.85688889 L8.60581143,13.6337778 L6.23501306,13.6337778 L6.23501306,5.99822222 L8.50891716,5.99822222 L8.50891716,7.04177778 L8.54180788,7.04177778 C8.85915884,6.44177778 9.63253514,5.80888889 10.787266,5.80888889 C13.1909551,5.80888889 13.6345353,7.38933333 13.6345353,9.44622222 L13.6345353,13.6337778 Z M3.55930885,4.95555556 C2.79659981,4.95555556 2.18145453,4.33866667 2.18145453,3.57866667 C2.18145453,2.81955556 2.79659981,2.20266667 3.55930885,2.20266667 C4.3175732,2.20266667 4.93449636,2.81955556 4.93449636,3.57866667 C4.93449636,4.33866667 4.3175732,4.95555556 3.55930885,4.95555556 Z M2.36990944,13.6337778 L4.74604145,13.6337778 L4.74604145,5.99822222 L2.36990944,5.99822222 L2.36990944,13.6337778 Z M14.8159342,0 L1.17962109,0 C0.528918273,0 0,0.516444444 0,1.15377778 L0,14.8453333 C0,15.4826667 0.528918273,16 1.17962109,16 L14.8159342,16 C15.4684149,16 16,15.4826667 16,14.8453333 L16,1.15377778 C16,0.516444444 15.4684149,0 14.8159342,0 L14.8159342,0 Z"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>
);

export default SvgFooterLinkedin;
