import React from 'react';

const Dxf = ({
  width = '26px',
  height = '34px',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 26 34" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>EDB35C00-8F52-4548-8399-7783EFE76DFB@1x</title>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="SKU-V1-(overlay)-sidebar-aperto" transform="translate(-954.000000, -498.000000)">
            <g id="CONTENUTI" transform="translate(164.000000, 108.000000)">
                <g id="SIDEBAR" transform="translate(697.000000, 189.000000)">
                    <g id="Aperto" transform="translate(67.000000, 88.000000)">
                        <g id="Group-5" transform="translate(20.000000, 112.000000)">
                            <g id="Group" transform="translate(6.000000, 1.500000)">
                                <path d="M0,0 L0,33 L25.5,33 L25.5,9.03464674 L25.3007812,8.81046196 L16.8007812,0.201766304 L16.5794271,0 L0,0 Z M1.41666667,1.43478261 L15.5833333,1.43478261 L15.5833333,10.0434783 L24.0833333,10.0434783 L24.0833333,31.5652174 L1.41666667,31.5652174 L1.41666667,1.43478261 Z M17,2.46603261 L23.0651042,8.60869565 L17,8.60869565 L17,2.46603261 Z" id="Shape" fill="#606060" fillRule="nonzero"></path>
                                <text id="TXT" fontFamily="Inter-Bold, Inter" fontSize="9" fontWeight="bold" fill="currentColor">
                                    <tspan x="3.93057528" y="28.5">JPG</tspan>
                                </text>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default Dxf;
