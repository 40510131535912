import React from 'react';

const CloseIcon = ({
  width = '24px',
  height = '24px',
  ...props
}: React.SVGProps<SVGSVGElement> ) => (
  <svg width={width} height={height} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="homepage-/-mobile-/-menu-open" transform="translate(-358.000000, -20.000000)" fill="#292A34">
            <g id="Menu-/-mobile-/-open" transform="translate(-17.000000, 0.000000)">
                <polygon id="Fill-1" points="397.599227 20 387.015464 30.5937992 376.400773 20 375 21.4062504 385.583763 32.0000496 375 42.5938488 376.400674 44 376.400872 44 387.015464 33.4062008 397.599128 44 397.599326 44 399 42.5938488 388.416139 32.0000496 399 21.4062504"></polygon>
            </g>
        </g>
    </g>
  </svg>
)

export default CloseIcon;
