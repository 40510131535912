import * as React from "react";
import { SVGProps } from "react";

const SvgFooterTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width={38} height={38} xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M12 0h14c6.627 0 12 5.373 12 12v14c0 6.627-5.373 12-12 12H12C5.373 38 0 32.627 0 26V12C0 5.373 5.373 0 12 0Z"
        id="footer-twitter_svg__a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#ffffff"
        stroke="#E4E4E4"
        d="M26 .5c3.176 0 6.05 1.287 8.132 3.368A11.464 11.464 0 0 1 37.5 12v14c0 3.176-1.287 6.05-3.368 8.132A11.464 11.464 0 0 1 26 37.5H12c-3.176 0-6.05-1.287-8.132-3.368A11.464 11.464 0 0 1 .5 26V12c0-3.176 1.287-6.05 3.368-8.132A11.464 11.464 0 0 1 12 .5Z"
      />
      <path
        d="M26.5 14.443a6.144 6.144 0 0 1-1.767.485 3.082 3.082 0 0 0 1.353-1.703 6.165 6.165 0 0 1-1.955.747 3.08 3.08 0 0 0-5.244 2.806 8.736 8.736 0 0 1-6.343-3.215 3.081 3.081 0 0 0 .952 4.109 3.064 3.064 0 0 1-1.393-.385c-.034 1.425.988 2.76 2.468 3.056a3.085 3.085 0 0 1-1.39.053 3.08 3.08 0 0 0 2.875 2.136 6.188 6.188 0 0 1-4.556 1.275 8.712 8.712 0 0 0 4.718 1.383c5.713 0 8.941-4.826 8.746-9.154a6.265 6.265 0 0 0 1.536-1.593Z"
        fill="#000"
      />
    </g>
  </svg>
);

export default SvgFooterTwitter;
