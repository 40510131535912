import React from 'react';

const ArrowSquareIcon = ({
  width = '9px',
  height = '12px',
  fill = '#000000',
  ...props
}: React.SVGProps<SVGSVGElement> ) => (
  <svg width={width} height={height} viewBox="0 0 9 12" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1.-Icons/Arrows/Small/Fw" fill={fill}>
        <polygon id="Line" transform="translate(4.500000, 6.265165) rotate(-90.000000) translate(-4.500000, -6.265165) " points="7.96966991 3.46966991 9.03033009 4.53033009 4.5 9.06066017 -0.0303300859 4.53033009 1.03033009 3.46966991 4.5 6.93933983"></polygon>
      </g>
    </g>
  </svg>
)

export default ArrowSquareIcon;
