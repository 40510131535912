import * as React from "react";
import { SVGProps } from "react";

const SvgFooterInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width={38} height={38} xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M12 0h14c6.627 0 12 5.373 12 12v14c0 6.627-5.373 12-12 12H12C5.373 38 0 32.627 0 26V12C0 5.373 5.373 0 12 0Z"
        id="footer-instagram_svg__a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#ffffff"
        stroke="#E4E4E4"
        d="M26 .5c3.176 0 6.05 1.287 8.132 3.368A11.464 11.464 0 0 1 37.5 12v14c0 3.176-1.287 6.05-3.368 8.132A11.464 11.464 0 0 1 26 37.5H12c-3.176 0-6.05-1.287-8.132-3.368A11.464 11.464 0 0 1 .5 26V12c0-3.176 1.287-6.05 3.368-8.132A11.464 11.464 0 0 1 12 .5Z"
      />
      <path
        d="M19 11.5c-2.037 0-2.292.009-3.092.045-2.724.125-4.237 1.636-4.362 4.362-.037.801-.046 1.056-.046 3.093s.009 2.293.045 3.093c.125 2.723 1.636 4.237 4.362 4.362.801.036 1.056.045 3.093.045s2.293-.009 3.093-.045c2.72-.125 4.238-1.636 4.361-4.362.037-.8.046-1.056.046-3.093s-.009-2.292-.045-3.092c-.122-2.721-1.636-4.237-4.362-4.362-.8-.037-1.056-.046-3.093-.046Zm0 3.649a3.851 3.851 0 1 0 0 7.703 3.851 3.851 0 0 0 0-7.703Zm0 6.351a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Zm4.004-7.403a.9.9 0 1 0 0 1.8.9.9 0 0 0 0-1.8Z"
        fill="#000"
      />
    </g>
  </svg>
);

export default SvgFooterInstagram;
