import React from 'react';

const Checkbox = ({
  width = '24px',
  height = '24px',
  ...props
}: React.SVGProps<SVGSVGElement> ) => (
  <svg width={width} height={height} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>@1x☑️ Checkbox</title>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeOpacity="0.7">
        <g id="PLP-Pagina-categoria-/--scelta-gamma" transform="translate(-183.000000, -599.000000)" stroke="#ACA49C" strokeWidth="1.5">
            <g id="FILTRI" transform="translate(165.000000, 464.000000)">
                <g id="2.-Forms-/-2.-Cells-/-1.-Cell" transform="translate(10.000000, 128.000000)">
                    <g id="Rectangle" transform="translate(8.000000, 7.000000)">
                        <rect x="0.75" y="0.75" width="22.5" height="22.5"></rect>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default Checkbox;
