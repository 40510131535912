import React from 'react';

const CloseCircleBlackIcon = ({
  width = '24px',
  height = '24px',
  ...props
}: React.SVGProps<SVGSVGElement> ) => (
  <svg width={width} height={height} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>2EDFAA39-FCCB-41F4-B9FD-99684EE0244A</title>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Realizzazioni-/-Reference-book-Copy" transform="translate(-1251.000000, -53.000000)" fill="currentColor" fillRule="nonzero">
            <g id="3.-Components/1.-Navigazione-/Instant-Search">
                <path d="M1263,53 C1256.373,53 1251,58.373 1251,65 C1251,71.627 1256.373,77 1263,77 C1269.627,77 1275,71.627 1275,65 C1275,58.373 1269.627,53 1263,53 Z M1267.707,68.293 C1268.098,68.684 1268.098,69.316 1267.707,69.707 C1267.512,69.902 1267.256,70 1267,70 C1266.744,70 1266.488,69.902 1266.293,69.707 L1263,66.414 L1259.707,69.707 C1259.512,69.902 1259.256,70 1259,70 C1258.744,70 1258.488,69.902 1258.293,69.707 C1257.902,69.316 1257.902,68.684 1258.293,68.293 L1261.586,65 L1258.293,61.707 C1257.902,61.316 1257.902,60.684 1258.293,60.293 C1258.684,59.902 1259.316,59.902 1259.707,60.293 L1263,63.586 L1266.293,60.293 C1266.684,59.902 1267.316,59.902 1267.707,60.293 C1268.098,60.684 1268.098,61.316 1267.707,61.707 L1264.414,65 L1267.707,68.293 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>
)

export default CloseCircleBlackIcon;
