import React from 'react';

const Document = ({
  width = '18px',
  height = '24px',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 18 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>7BB1785B-A3C4-4D4A-81B9-869FC714328D@1x</title>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="PDP-V1---(versioni)" transform="translate(-1119.000000, -1617.000000)" fill="#000000">
            <g id="Specifiche-tecniche" transform="translate(164.000000, 1455.000000)">
                <g id="Tabella-" transform="translate(2.000000, 87.000000)">
                    <g id="Garanzia" transform="translate(927.000000, 18.000000)">
                        <g id="1.-Icons/16px/List" transform="translate(26.250000, 57.750000)">
                            <path d="M0,0 L0,22.5 L17.5,22.5 L17.5,5.70612981 L17.2539062,5.43569712 L12.0039062,0.243389423 L11.7304688,0 L0,0 Z M1.75,1.73076923 L10.5,1.73076923 L10.5,6.92307692 L15.75,6.92307692 L15.75,20.7692308 L1.75,20.7692308 L1.75,1.73076923 Z M12.25,2.97475962 L14.4921875,5.19230769 L12.25,5.19230769 L12.25,2.97475962 Z M4.375,8.65384615 L4.375,10.3846154 L13.125,10.3846154 L13.125,8.65384615 L4.375,8.65384615 Z M4.375,12.1153846 L4.375,13.8461538 L13.125,13.8461538 L13.125,12.1153846 L4.375,12.1153846 Z M4.375,15.5769231 L4.375,17.3076923 L13.125,17.3076923 L13.125,15.5769231 L4.375,15.5769231 Z" id="Icon"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default Document;
