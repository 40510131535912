import * as React from "react";
import { SVGProps } from "react";

const SvgFooterYoutube = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width={38} height={38} xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M12 0h14c6.627 0 12 5.373 12 12v14c0 6.627-5.373 12-12 12H12C5.373 38 0 32.627 0 26V12C0 5.373 5.373 0 12 0Z"
        id="footer-youtube_svg__a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#ffffff"
        stroke="#E4E4E4"
        d="M26 .5c3.176 0 6.05 1.287 8.132 3.368A11.464 11.464 0 0 1 37.5 12v14c0 3.176-1.287 6.05-3.368 8.132A11.464 11.464 0 0 1 26 37.5H12c-3.176 0-6.05-1.287-8.132-3.368A11.464 11.464 0 0 1 .5 26V12c0-3.176 1.287-6.05 3.368-8.132A11.464 11.464 0 0 1 12 .5Z"
      />
      <path
        d="M23.76 13.865c-2.253-.154-7.27-.153-9.52 0-2.435.166-2.722 1.638-2.74 5.51.018 3.866.303 5.343 2.74 5.51 2.25.153 7.267.154 9.52 0 2.435-.166 2.722-1.637 2.74-5.51-.018-3.866-.302-5.343-2.74-5.51Zm-6.635 8.01v-5l5 2.496-5 2.504Z"
        fill="#000"
      />
    </g>
  </svg>
);

export default SvgFooterYoutube;
