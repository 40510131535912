import React from 'react';

const Qualita1 = ({
  width = '85px',
  height = '62px',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 85 62" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>1FB39A6A-BD23-4596-999C-22F5A76383F5@1x</title>
    <defs>
        <polygon id="qualita1-path-1" points="0 0 84.9998199 0 84.9998199 38.4327485 0 38.4327485"></polygon>
        <polygon id="qualita1-path-3" points="0 62 85 62 85 0 0 0"></polygon>
    </defs>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="About--Normativa-qualità-aperta-" transform="translate(-1057.000000, -5986.000000)">
            <g id="Disano-green" transform="translate(0.000000, 5188.000000)">
                <g id="Controlli-qulaità" transform="translate(0.000000, 604.000000)">
                    <g id="Icone" transform="translate(309.000000, 169.999277)">
                        <g id="ico-step-3" transform="translate(748.000000, 24.000000)">
                            <polygon id="Fill-1" fill="#FF5C04" points="40.6991525 54.748538 44.3008475 54.748538 44.3008475 52.5730994 40.6991525 52.5730994"></polygon>
                            <polygon id="Fill-2" fill="#FF5C04" points="8.28389831 54.748538 11.8855932 54.748538 11.8855932 52.5730994 8.28389831 52.5730994"></polygon>
                            <polygon id="Fill-3" fill="#FF5C04" points="29.8940678 54.748538 33.4957627 54.748538 33.4957627 52.5730994 29.8940678 52.5730994"></polygon>
                            <polygon id="Fill-4" fill="#FF5C04" points="19.0889831 54.748538 22.690678 54.748538 22.690678 52.5730994 19.0889831 52.5730994"></polygon>
                            <polygon id="Fill-5" fill="#FF5C04" points="73.1144068 54.748538 76.7161017 54.748538 76.7161017 52.5730994 73.1144068 52.5730994"></polygon>
                            <polygon id="Fill-6" fill="#FF5C04" points="51.5042373 54.748538 55.1059322 54.748538 55.1059322 52.5730994 51.5042373 52.5730994"></polygon>
                            <polygon id="Fill-7" fill="#FF5C04" points="62.309322 54.748538 65.9110169 54.748538 65.9110169 52.5730994 62.309322 52.5730994"></polygon>
                            <g id="Group-13">
                                <g id="Group-10" transform="translate(0.000180, 23.566889)">
                                    <mask id="qualita1-mask-2" fill="white">
                                        <use xlinkHref="#qualita1-path-1"></use>
                                    </mask>
                                    <g id="Clip-9"></g>
                                    <path d="M76.7161017,36.2573099 L8.28389831,36.2573099 C4.90730932,36.2573099 2.16101695,33.4926901 2.16101695,30.0935673 C2.16101695,26.6944444 4.90730932,23.9298246 8.28389831,23.9298246 L76.7161017,23.9298246 C80.0926907,23.9298246 82.8389831,26.6944444 82.8389831,30.0935673 C82.8389831,33.4926901 80.0926907,36.2573099 76.7161017,36.2573099 L76.7161017,36.2573099 Z M83.9429025,26.0236842 L75.8877119,7.86602339 L75.7436441,7.56327485 C73.7212924,3.33748538 72.1257415,0 67.7118644,0 L65.9110169,0 L65.9110169,2.1754386 L67.7118644,2.1754386 C70.6382415,2.1754386 71.7511653,4.23122807 73.796928,8.50777778 L79.9810381,22.4342105 C78.9779661,21.9991228 77.8758475,21.754386 76.7161017,21.754386 L8.28389831,21.754386 C7.12415254,21.754386 6.02023305,22.0009357 5.01716102,22.4360234 L11.0590042,8.81233918 L11.2048729,8.50777778 C13.2488347,4.23122807 14.3617585,2.1754386 17.2881356,2.1754386 L19.0889831,2.1754386 L19.0889831,0 L17.2881356,0 C12.8742585,0 11.2787076,3.33748538 9.25635593,7.56327485 L1.05889831,26.0236842 L1.08951271,26.0381871 C0.415995763,27.2437427 0,28.614269 0,30.0935673 C0,34.692807 3.71514831,38.4327485 8.28389831,38.4327485 L76.7161017,38.4327485 C81.2830508,38.4327485 85,34.692807 85,30.0935673 C85,28.614269 84.5822034,27.2455556 83.9086864,26.04 L83.9429025,26.0236842 Z" id="Fill-8" fill="#FF5C04" mask="url(#qualita1-mask-2)"></path>
                                </g>
                                <mask id="qualita1-mask-4" fill="white">
                                    <use xlinkHref="#qualita1-path-3"></use>
                                </mask>
                                <g id="Clip-12"></g>
                                <path d="M23.7711864,36.2573099 L61.2288136,36.2573099 L61.2288136,11.2397661 L23.7711864,11.2397661 L23.7711864,36.2573099 Z M27.0235169,2.1754386 L57.9764831,2.1754386 L60.7137712,9.06432749 L24.2880297,9.06432749 L27.0235169,2.1754386 Z M58.7076271,0 L26.2923729,0 C25.8511653,0 25.453178,0.270116959 25.2893008,0.683450292 L21.6876059,9.74777778 C21.6371822,9.87649123 21.6101695,10.014269 21.6101695,10.1520468 L21.6101695,37.3450292 C21.6101695,37.9450877 22.0945975,38.4327485 22.690678,38.4327485 L62.309322,38.4327485 C62.9054025,38.4327485 63.3898305,37.9450877 63.3898305,37.3450292 L63.3898305,10.1520468 C63.3898305,10.014269 63.3628178,9.87649123 63.3123941,9.74777778 L59.7106992,0.683450292 C59.546822,0.270116959 59.1488347,0 58.7076271,0 L58.7076271,0 Z" id="Fill-11" fill="#FF5C04" mask="url(#qualita1-mask-4)"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default Qualita1;
