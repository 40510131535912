import React from 'react';

const CheckboxChecked = ({
  width = '24px',
  height = '24px',
  ...props
}: React.SVGProps<SVGSVGElement> ) => (
  <svg width={width} height={height} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>@1x☑️ Checkbox</title>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="PLP-Pagina-categoria-/--scelta-gamma" transform="translate(-183.000000, -633.000000)">
            <g id="FILTRI" transform="translate(165.000000, 464.000000)">
                <g id="2.-Forms-/-2.-Cells-/-1.-Cell" transform="translate(10.000000, 162.000000)">
                    <g id="Group" transform="translate(8.000000, 7.000000)">
                        <rect id="Rectangle" fill="#1288CF" x="0" y="0" width="24" height="24"></rect>
                        <polyline id="Check" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="square" fillRule="nonzero" points="6 11.5805191 10.5073118 16.0878309 18.7280249 7.86711783"></polyline>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default CheckboxChecked;
