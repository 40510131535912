import React from 'react';

const BurgerIcon = ({
  width = '26px',
  height = '18px',
  ...props
}: React.SVGProps<SVGSVGElement> ) => (
  <svg width={width} height={height} viewBox="0 0 26 18" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
        <polygon id="burgericon-path-1" points="2.91891511e-13 -3.19744231e-14 24.985 -3.19744231e-14 24.985 17.3200091 2.91891511e-13 17.3200091"></polygon>
    </defs>
    <g id="mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Risultato-ricerca-/-mobile-" transform="translate(-356.000000, -25.000000)">
            <g id="burger-icon" transform="translate(356.000000, 25.000000)">
                <g id="Clip-3" transform="translate(0.015000, 0.000000)">
                    <mask id="burgericon-mask-2" fill="white">
                        <use href="#burgericon-path-1"></use>
                    </mask>
                    <g id="Clip-2"></g>
                </g>
                <path d="M1.42108547e-13,2.47467273 L25.20125,2.47467273 L25.20125,4.97379915e-14 L1.42108547e-13,4.97379915e-14 L1.42108547e-13,2.47467273 Z M1.42108547e-13,9.89770909 L25.20125,9.89770909 L25.20125,7.42303636 L1.42108547e-13,7.42303636 L1.42108547e-13,9.89770909 Z M1.42108547e-13,17.3200091 L25.20125,17.3200091 L25.20125,14.8460727 L1.42108547e-13,14.8460727 L1.42108547e-13,17.3200091 Z" id="Fill-1" fill="#000000"></path>
            </g>
        </g>
    </g>
  </svg>
)

export default BurgerIcon;
