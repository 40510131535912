import React from 'react';

const CloseCircleIcon = ({
  width = '24px',
  height = '24px',
  ...props
}: React.SVGProps<SVGSVGElement> ) => (
  <svg width={width} height={height} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>362DE2DD-09D8-4C4C-897B-F14FAAB1081A@1x</title>
    <g id="symbol" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" fill="currentColor" transform="translate(3.000000, 3.000000)">
            <path d="M9,16.5 C13.1421356,16.5 16.5,13.1421356 16.5,9 C16.5,4.85786438 13.1421356,1.5 9,1.5 C4.85786438,1.5 1.5,4.85786438 1.5,9 C1.5,13.1421356 4.85786438,16.5 9,16.5 Z M9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 Z" id="Combined-Shape"></path>
            <polygon id="🔹-Icon-Color" transform="translate(9.000000, 9.000000) rotate(-315.000000) translate(-9.000000, -9.000000) " points="15 9.85714286 9.85714286 9.85714286 9.85714286 15 8.14285714 15 8.14285714 9.85714286 3 9.85714286 3 8.14285714 8.14285714 8.14285714 8.14285714 3 9.85714286 3 9.85714286 8.14285714 15 8.14285714"></polygon>
        </g>
    </g>
</svg>
)

export default CloseCircleIcon;
