import React from 'react';

const SvgChevronRight = ({
  height = 24,
  width = 24,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 7 12"
    {...props}
  >
    <title>69BCE269-4405-4DBF-B40F-FB015F0CD652@1x</title>
    <g id="SITO" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Contatti" transform="translate(-391.000000, -490.000000)" fill="currentColor">
        <g id="sidebar-menu" transform="translate(166.000000, 464.000000)">
          <g id="4.-Icons-/-0.-Icon-Area" transform="translate(225.204505, 26.234835)">
            <polygon id="Line" transform="translate(3.295495, 5.530330) rotate(-90.000000) translate(-3.295495, -5.530330) " points="3.29549513 6.70450487 7.76516504 2.23483496 8.82582521 3.29549513 3.29549513 8.82582521 -2.23483496 3.29549513 -1.17417479 2.23483496"></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgChevronRight;
