import React from 'react';

const Qualita2 = ({
    width = '85px',
    height = '71px',
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg width={width} height={height} viewBox="0 0 85 71" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>F0D321B0-5C0B-42AC-93BB-4C3F4345E4E5@1x</title>
        <defs>
            <polygon id="qualita2-path-1" points="0 0 85 0 85 30.8369803 0 30.8369803"></polygon>
        </defs>
        <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="About--Normativa-qualità-aperta-" transform="translate(-678.000000, -5977.000000)">
                <g id="Disano-green" transform="translate(0.000000, 5188.000000)">
                    <g id="Controlli-qulaità" transform="translate(0.000000, 604.000000)">
                        <g id="Icone" transform="translate(309.000000, 169.999277)">
                            <g id="ico-step-2" transform="translate(369.000000, 15.000000)">
                                <polygon id="Fill-1" fill="#FF5C04" points="73.1143816 63.8285509 76.7160841 63.8285509 76.7160841 61.6788015 73.1143816 61.6788015"></polygon>
                                <polygon id="Fill-2" fill="#FF5C04" points="8.28373577 63.8285509 11.8854383 63.8285509 11.8854383 61.6788015 8.28373577 61.6788015"></polygon>
                                <polygon id="Fill-3" fill="#FF5C04" points="19.0888434 63.8285509 22.690546 63.8285509 22.690546 61.6788015 19.0888434 61.6788015"></polygon>
                                <polygon id="Fill-4" fill="#FF5C04" points="29.893951 63.8285509 33.4956536 63.8285509 33.4956536 61.6788015 29.893951 61.6788015"></polygon>
                                <polygon id="Fill-5" fill="#FF5C04" points="40.6990587 63.8285509 44.3007612 63.8285509 44.3007612 61.6788015 40.6990587 61.6788015"></polygon>
                                <polygon id="Fill-6" fill="#FF5C04" points="51.5041663 63.8285509 55.1058689 63.8285509 55.1058689 61.6788015 51.5041663 61.6788015"></polygon>
                                <polygon id="Fill-7" fill="#FF5C04" points="62.309274 63.8285509 65.9109765 63.8285509 65.9109765 61.6788015 62.309274 61.6788015"></polygon>
                                <g id="Group-13">
                                    <g id="Group-10" transform="translate(0.000000, 40.163020)">
                                        <mask id="qualita2-mask-2" fill="white">
                                            <use xlinkHref="#qualita2-path-1"></use>
                                        </mask>
                                        <g id="Clip-9"></g>
                                        <path d="M76.7162642,28.6872309 L8.28391586,28.6872309 C4.90731972,28.6872309 2.16102153,25.9529792 2.16102153,22.5911943 C2.16102153,19.2294095 4.90731972,16.4933648 8.28391586,16.4933648 L37.8178767,16.4933648 L54.3857084,16.4933648 L59.428092,16.4933648 L75.9959237,16.4933648 L76.7162642,16.4933648 C80.0928604,16.4933648 82.8391586,19.2294095 82.8391586,22.5911943 C82.8391586,25.9529792 80.0928604,28.6872309 76.7162642,28.6872309 L76.7162642,28.6872309 Z M39.9788983,14.3436154 L52.2246869,14.3436154 L52.2246869,2.14974937 L39.9788983,2.14974937 L39.9788983,14.3436154 Z M61.5891135,14.3436154 L73.8349022,14.3436154 L73.8349022,2.14974937 L61.5891135,2.14974937 L61.5891135,14.3436154 Z M76.7162642,14.3436154 L75.9959237,14.3436154 L75.9959237,0 L59.428092,0 L59.428092,14.3436154 L54.3857084,14.3436154 L54.3857084,0 L37.8178767,0 L37.8178767,14.3436154 L8.28391586,14.3436154 C3.71515618,14.3436154 0,18.0424753 0,22.5911943 C0,27.1381204 3.71515618,30.8369803 8.28391586,30.8369803 L76.7162642,30.8369803 C81.2850239,30.8369803 85.0001801,27.1381204 85.0001801,22.5911943 C85.0001801,18.0424753 81.2850239,14.3436154 76.7162642,14.3436154 L76.7162642,14.3436154 Z" id="Fill-8" fill="#FF5C04" mask="url(#qualita2-mask-2)"></path>
                                    </g>
                                    <path d="M34.2159941,33.4009015 L29.1736105,35.9110342 L29.1736105,29.4044117 L12.6057788,29.4044117 L12.6057788,35.9110342 L7.56339526,33.4009015 L7.56339526,22.1806083 L20.8896947,15.5466862 L34.2159941,22.1806083 L34.2159941,33.4009015 Z M14.7668004,43.7480271 L27.012589,43.7480271 L27.012589,31.554161 L14.7668004,31.554161 L14.7668004,43.7480271 Z M19.8091839,0 L19.8091839,13.6784303 L5.40237373,20.850238 L5.40237373,34.7312719 L12.6057788,38.3171757 L12.6057788,45.8995694 L29.1736105,45.8995694 L29.1736105,38.3171757 L36.3770156,34.7312719 L36.3770156,20.850238 L21.9702054,13.6784303 L21.9702054,0 L19.8091839,0 Z" id="Fill-11" fill="#FF5C04"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Qualita2;
