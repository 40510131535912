import React from 'react';

const Download = ({
  width = '21px',
  height = '21px',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>4CF2B103-4984-48A2-87F1-A4D5D65C1DC8@1x</title>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="PDP-V1---(versioni)" transform="translate(-1211.000000, -1619.000000)" fill="currentColor" fillRule="nonzero">
            <g id="Specifiche-tecniche" transform="translate(164.000000, 1455.000000)">
                <g id="Tabella-" transform="translate(2.000000, 87.000000)">
                    <g id="Download" transform="translate(1023.000000, 18.000000)">
                        <g id="icons8-scaricare" transform="translate(22.025478, 59.025478)">
                            <path d="M9.1290736,0 C8.57004176,0 8.11633475,0.453707006 8.11633475,1.01273885 L8.11633475,9.11464968 L4.57174876,9.11464968 C4.29208865,9.11464968 4.06537934,9.341359 4.06537934,9.62101911 C4.06537934,9.755309 4.11876233,9.88409135 4.21372978,9.9790385 C4.22385048,9.98937578 4.23440953,9.99927459 4.24537787,10.0087077 L9.41588429,14.8844923 L9.44951025,14.9161404 C9.63696914,15.0924099 9.88449627,15.1907133 10.1418125,15.1910828 C10.3991286,15.1907133 10.6466558,15.0924099 10.8341147,14.9161404 L10.8479608,14.9042721 C10.8512874,14.9003412 10.854584,14.896385 10.8578502,14.8924038 L16.0263792,10.0185981 C16.0366353,10.0097675 16.0465334,10.0005293 16.0560494,9.99090577 L16.0639609,9.98497213 C16.0659555,9.98301068 16.0679337,9.98103273 16.0698955,9.9790385 C16.1648628,9.88409129 16.2182456,9.75530894 16.2182456,9.62101911 C16.2182456,9.341359 15.9915363,9.11464968 15.7118762,9.11464968 L12.1672902,9.11464968 L12.1672902,1.01273885 C12.1672902,0.453707006 11.7135832,0 11.1545513,0 L10.1418125,0 L9.1290736,0 Z M1.02716278,18.2292994 C0.661932771,18.2241341 0.322214133,18.4160234 0.138091771,18.7314891 C-0.0460305904,19.0469547 -0.0460305904,19.4371217 0.138091771,19.7525874 C0.322214133,20.068053 0.661932771,20.2599423 1.02716278,20.2547771 L19.2564621,20.2547771 C19.6216921,20.2599423 19.9614108,20.068053 20.1455331,19.7525874 C20.3296555,19.4371217 20.3296555,19.0469547 20.1455331,18.7314891 C19.9614108,18.4160234 19.6216921,18.2241341 19.2564621,18.2292994 L1.02716278,18.2292994 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export default Download;
